import { Column, ContentContainer, LayoutGrid, Text } from '@codecademy/gamut';
import { CurriculumCardData } from '@mono/data/curriculum-cards';

import { catalogHomePageName } from './const';
import { ExternalOrgContents } from './ExternalOrgContents';
import { FeaturedContainers } from './FeaturedContainers';
import { HubSpotlight, HubSpotlightProps } from './HubSpotlight';
import { PaginatedCatalog } from './PaginatedCatalog';
import { PaginatedCatalogContextProvider } from './PaginatedCatalog/context';
import { CatalogHomeSideNav } from './SideNav';
import { SortingQuizBanner } from './SortingQuizBanner';
import { TopCategories } from './TopCategories';
import {
  CategoryDataCollection,
  ExternalOrgContent,
  PaginatedCatalogResults,
} from './types';

export type CatalogHomeProps = {
  categoryDataCollection: CategoryDataCollection;
  featuredContainers: CurriculumCardData[];
  hubSpotlight: HubSpotlightProps;
  initialCatalogResults: PaginatedCatalogResults;
  isTeamsUser: boolean;
  externalOrgContents: ExternalOrgContent[];
};

export const CatalogHome: React.FC<CatalogHomeProps> = (props) => {
  const {
    categoryDataCollection: categories,
    initialCatalogResults,
    hubSpotlight,
    featuredContainers,
    isTeamsUser,
    externalOrgContents,
  } = props;

  return (
    <ContentContainer as="main">
      <LayoutGrid>
        <Column
          gridColumnEnd={{
            _: 'span 12',
            lg: 'span 3',
          }}
          py={{ _: 16, lg: 0 }}
          size={{ lg: 3 }}
        >
          <CatalogHomeSideNav
            topics={categories.standard}
            sideItems={categories.special}
            isTeamsUser={isTeamsUser}
          />
        </Column>
        <Column size={{ lg: 9 }} rowGap={32} pb={64} pt={{ _: 8, sm: 24 }}>
          {!isTeamsUser && (
            <SortingQuizBanner
              trackingData={{
                page_name: catalogHomePageName,
                target: 'sorting_quiz',
              }}
            />
          )}

          <Text as="h1" fontSize={34} mb={24}>
            Explore the catalog
          </Text>

          {/* teams+ users only */}
          {isTeamsUser && externalOrgContents.length > 0 && (
            <ExternalOrgContents contents={externalOrgContents} />
          )}

          <TopCategories categories={categories.popular} />

          <FeaturedContainers containers={featuredContainers} />

          <HubSpotlight {...hubSpotlight} />
        </Column>
        <Column>
          <PaginatedCatalogContextProvider
            initialCatalogResults={initialCatalogResults}
          >
            <PaginatedCatalog />
          </PaginatedCatalogContextProvider>
        </Column>
      </LayoutGrid>
    </ContentContainer>
  );
};

import { Anchor, Box, FlexBox, Text } from '@codecademy/gamut';
import { theme } from '@codecademy/gamut-styles';
import styled from '@emotion/styled';
import { useTracking } from '@mono/data/use-tracking';
import { UserClickData } from '@mono/tracking';
import { NextLink } from '@mono/ui/components';
import React from 'react';

import { getSortingQuizPath } from '~/libs/paths';

import { ReactComponent as BallAndTriangleSVG } from './assets/ball_and_triangle.svg';
import { ReactComponent as BlackAndWhiteDotsSVG } from './assets/black_and_white_dots.svg';
import { ReactComponent as SquiggleSVG } from './assets/squiggle.svg';

const BallAndTriangle = styled(BallAndTriangleSVG)`
  width: 20%;
  max-width: fit-content;
`;

const BlackAndWhiteDots = styled(BlackAndWhiteDotsSVG)`
  position: absolute;
  bottom: 0;
  left: 0.75rem;
`;

const Squiggle = styled(SquiggleSVG)`
  width: 70px;
  vertical-align: middle;
`;

const BannerContainer = styled(FlexBox)`
  user-select: none;
`.withComponent(Anchor);

const ActionText = styled(Text)`
  color: ${theme.colors.hyper};
  ${BannerContainer}:hover & {
    text-decoration: underline;
  }
`;

export type SortingQuizBannerProps = {
  trackingData: UserClickData;
};

export const SortingQuizBanner: React.FC<SortingQuizBannerProps> = ({
  trackingData,
}) => {
  const { trackUserClick } = useTracking();

  return (
    <aside>
      <NextLink href={getSortingQuizPath()} passHref target="_blank">
        <BannerContainer
          variant="interface"
          bg="yellow"
          minHeight="4.5rem"
          alignItems="flex-end"
          justifyContent="space-between"
          onClick={() => trackUserClick(trackingData)}
          aria-labelledby="sorting-quiz-banner-text"
        >
          <Box
            position="relative"
            width="13%"
            minWidth={75}
            overflow="hidden"
            aria-hidden
          >
            <Squiggle />
            <BlackAndWhiteDots />
          </Box>
          <Box
            alignSelf="center"
            lineHeight="title"
            px={4}
            id="sorting-quiz-banner-text"
          >
            <Text fontSize={{ _: 18, sm: 22 }} display="inline">
              Not sure where to begin? &nbsp;
            </Text>
            <ActionText
              fontSize={{ _: 18, sm: 22 }}
              fontWeight="bold"
              aria-label="Take our quiz"
            >
              Take our quiz &rarr;
            </ActionText>
          </Box>
          <BallAndTriangle aria-hidden />
        </BannerContainer>
      </NextLink>
    </aside>
  );
};

import { getCatalogPath } from '../../../libs/paths';
import { CatalogCategoryType } from '../types';
import cybersecurityIllustration from './assets/cybersecurityIllustration.svg';
import { SortBy } from './PaginatedCatalog/SortByDropdown/types';

export const catalogHomePageName = 'home_catalog';

export const aiSubject = {
  title: 'AI',
  slug: 'artificial-intelligence',
  type: 'Category' as const,
  categoryType: 'subject',
};

export const cybersecuritySpotlightContent = {
  eyebrow: 'Recently added',
  header: 'New in cybersecurity',
  description:
    'Grow your skills with free video-based cybersecurity courses and build foundational knowledge for popular certifications. Explore what’s new in network and data security, risk management, and more.',
  slug: 'cybersecurity',
  cta: {
    text: 'View courses',
    href: getCatalogPath({
      categoryType: CatalogCategoryType.Subject,
      category: 'cybersecurity',
    }),
  },
  imageSrc: cybersecurityIllustration,
};

export const CATALOG_PAGE_SIZE = 48;

export const DEFAULT_SORT_BY = SortBy.POPULAR;

export const featuredContainerSlugs = [
  'introduction-to-cybersecurity',
  'full-stack-engineer-career-path',
  'code-foundations',
  'learn-html',
  'computer-science',
  'learn-how-to-build-websites',
  'intro-to-chatgpt',
  'bi-data-analyst',
  'analyze-data-with-sql',
  'introduction-to-javascript',
  'front-end-engineer-career-path',
  'machine-learning-ai-engineering-foundations',
];
